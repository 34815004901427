import React from 'react';

import PropTypes from 'prop-types';
import { Col, Container, Image, Row } from 'react-bootstrap';

import './index.scss';

const Investor = ({
  image,
  alt,
  title,
  name,
  twitter,
  linkedin,
  lineBreak
}) => {
  return (
    <Col className="Investors__col">
      <Image src={image} className="Investors__image" alt={alt} />
      <div className="Investors__border"></div>
      {lineBreak ? (
        <p style={{ marginBottom: '10px' }}>{title}</p>
      ) : (
        <p>{title}</p>
      )}
      <h4>{name}</h4>
      <a href={twitter} rel="noopener noreferrer" target="_blank">
        <Image src="../images/twitter.svg" alt="twitter" />
      </a>
      <a href={linkedin} rel="noopener noreferrer" target="_blank">
        <Image src="../images/linkedin.svg" alt="linkedin" />
      </a>
    </Col>
  );
};

Investor.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  lineBreak: PropTypes.bool
};

const InvestorMobile = ({
  image,
  alt,
  title,
  name,
  twitter,
  linkedin,
  lineBreak
}) => {
  return (
    <Col className="Investors__item mt-4 mt-lg-0">
      <Image src={image} className="Investors__image" alt={name} />
      <h4>{name}</h4>
      {lineBreak ? (
        <p>{title}</p>
      ) : (
        <p style={{ marginBottom: '40px' }}>{title}</p>
      )}
      <Row className="Investor__socials">
        <button href={twitter} rel="noopener noreferrer" target="_blank">
          <Image src="../images/twitter.svg" alt="twitter" />
        </button>
        <button href={linkedin} rel="noopener noreferrer" target="_blank">
          <Image src="../images/linkedin.svg" alt="linkedin" />
        </button>
      </Row>
    </Col>
  );
};

InvestorMobile.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  lineBreak: PropTypes.bool
};

const Investors = ({ header, subHeader, backers, bottomText }) => {
  return (
    <div>
      <Container className="Investors">
        <h1>{header}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: subHeader
          }}
          className="Investors__subheader"
        ></div>
        <Row>
          {backers.map((backer, i) => {
            return (
              <Investor
                key={backer.order}
                image={backer.backerPhoto.url}
                alt={backer.backerPhoto.title}
                title={backer.backerTitle}
                name={backer.backerName}
                twitter={backer.backerTwitterUrl}
                linkedin={backer.backerLinkedinUrl}
                lineBreak={i === 0}
              />
            );
          })}
        </Row>
        <h3 className="bottom-text">{bottomText}</h3>
      </Container>
      <Container className="InvestorMobile site-wrap">
        <h1>{header}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: subHeader
          }}
          className="Investors__subheader"
        ></div>
        <Row className="Investors__items">
          {backers.map((backer, i) => {
            return (
              <InvestorMobile
                key={backer.order}
                image={backer.backerPhoto.url}
                alt={backer.backerPhoto.title}
                title={backer.backerTitle}
                name={backer.backerName}
                twitter={backer.backerTwitterUrl}
                linkedin={backer.backerLinkedinUrl}
                lineBreak={i === 0 || i === 3}
              />
            );
          })}
        </Row>
        <br />
        <br />
        <h3 className="bottom-text">{bottomText}</h3>
      </Container>
    </div>
  );
};

Investors.propTypes = {
  backers: PropTypes.array,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  bottomText: PropTypes.string
};

export default Investors;
