import React from 'react';

import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';

import './index.scss';

const Careers = ({
  label,
  header,
  subheader,
  linkText,
  linkUrl,
  valueOneText,
  valueOneImage,
  valueTwoText,
  valueTwoImage,
  valueThreeText,
  valueThreeImage,
  bottomText
}) => {
  return (
    <section className="Careers site-wrap">
      <div className="Intro__header">
        <div className="Intro__left">
          <h2>{label}</h2>
        </div>
        <div className="Intro__right">
          <a target="_blank" rel="noopener noreferrer" href={linkUrl}>
            {linkText}
          </a>
        </div>
      </div>
      <div>
        <h3>{header}</h3>
        <h4>{subheader}</h4>
        <div className="CareersValues__mobile">
          <Row>
            <Image
              src={valueOneImage.url}
              alt={valueOneImage.title}
              style={{ width: '30%' }}
            />
            <h4
              style={{
                width: '50%',
                overflowWrap: 'break-word',
                marginLeft: '25px'
              }}
            >
              {valueOneText}
            </h4>
          </Row>
          <Row>
            <Image
              src={valueTwoImage.url}
              alt={valueTwoImage.title}
              style={{ width: '30%' }}
            />
            <h4
              style={{
                width: '50%',
                overflowWrap: 'break-word',
                marginLeft: '25px'
              }}
            >
              {valueTwoText}
            </h4>
          </Row>
          <Row className="d-flex flex-row">
            <Image
              src={valueThreeImage.url}
              style={{ width: '30%', marginLeft: '25px' }}
              alt={valueThreeImage.title}
            />
            <h4 style={{ width: '50%', overflowWrap: 'break-word' }}>
              {valueThreeText}
            </h4>
          </Row>
        </div>
        <Row className="CareersValues__desktop">
          <Col>
            <Image src={valueOneImage.url} alt={valueOneImage.title} />
            <h4>{valueOneText}</h4>
          </Col>
          <Col>
            <Image src={valueTwoImage.url} alt={valueTwoImage.title} />
            <h4>{valueTwoText}</h4>
          </Col>
          <Col>
            <Image
              src={valueThreeImage.url}
              style={{ marginLeft: '50px' }}
              alt={valueThreeImage.title}
            />
            <h4 style={{ marginTop: '-25px' }}>{valueThreeText}</h4>
          </Col>
        </Row>
        <h4 className="Careers__contact">{bottomText}</h4>
        <div className="open_positions">
          <a target="_blank" rel="noreferrer" href={linkUrl}>
            {linkText}
          </a>
        </div>
      </div>
    </section>
  );
};

Careers.propTypes = {
  header: PropTypes.string,
  label: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  subheader: PropTypes.string,
  valueOneImage: PropTypes.obj,
  valueOneText: PropTypes.string,
  valueTwoImage: PropTypes.obj,
  valueTwoText: PropTypes.string,
  valueThreeImage: PropTypes.obj,
  valueThreeText: PropTypes.string,
  bottomText: PropTypes.string
};

export default Careers;
