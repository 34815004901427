import React, { useEffect, useState } from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import Careers from '../components/About/Careers';
import Investors from '../components/About/Investors';
import Journey from '../components/About/Journey';
import VentureCapitals from '../components/About/VentureCapitals';
import FlodeskModal from '../components/Flodesk/SignupModal';

import '../scss/styles.scss';
import './about.scss';

const AboutPage = () => {
  const aboutPageData = useStaticQuery(graphql`
    query {
      allContentfulAboutPage {
        nodes {
          heroHeader
          heroHeaderSpan
          heroSubheader {
            childMarkdownRemark {
              html
            }
          }
          headerButtonText
          quote
          ourStoryHeader
          ourStoryContent {
            childMarkdownRemark {
              html
            }
          }
          vrindaNameSpan
          vrindaSignatureImage {
            title
            url
          }
          ourStoryImage {
            title
            url
          }
          ourStoryButtonText
          ventureCapitalHeader
          ventureCapitalLogos {
            title
            url
          }
          backersHeader
          backersSubheader {
            childMarkdownRemark {
              html
            }
          }
          backers {
            order
            backerPhoto {
              title
              url
            }
            backerTitle
            backerName
            backerTwitterUrl
            backerLinkedinUrl
          }
          backersBottomText
          careersLabel
          careersHeader
          careersSubheader
          careersLinkText
          careerLinkUrl
          careerValueOneText
          careerValueOneImage {
            title
            url
          }
          careerValueTwoText
          careerValueTwoImage {
            title
            url
          }
          careerValueThreeText
          careerValueThreeImage {
            title
            url
          }
          careersBottomText
          ourJourneyHeader
          ourJourneyLinkText
          journeyOneText
          journeyOneImage {
            title
            url
          }
          journeyOneUrl
          journeyTwoText
          journeyTwoImage {
            title
            url
          }
          journeyTwoUrl
          journeyThreeText
          journeyThreeImage {
            title
            url
          }
          journeyThreeUrl
        }
      }
    }
  `);

  let isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const data = get(aboutPageData, 'allContentfulAboutPage.nodes[0]', []);

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const [modalShow, setModalShow] = useState(false);

  return (
    hasMounted && (
      <main>
        <div className="About__hero">
          <h1>
            {data.heroHeader}
            <span style={{ color: '#60034c', fontWeight: 'normal' }}>
              {` `} {data.heroHeaderSpan}
            </span>
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.heroSubheader?.childMarkdownRemark?.html
            }}
          ></div>
          <div className="signup__button">
            <Link
              className="purple-button"
              to="/about"
              onClick={() => setModalShow(true)}
            >
              {data.headerButtonText}
            </Link>
          </div>
        </div>
        <Container className="OurStory site-wrap" fluid>
          <blockquote>{data.quote}</blockquote>
          <h2>{data.ourStoryHeader}</h2>
          <Row>
            <Col className="OurStory__col">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.ourStoryContent?.childMarkdownRemark?.html
                }}
                className="OurStory__DesktopText"
              ></div>
              <h2 className="OurStory__name">{data.vrindaNameSpan}</h2>
              <div>
                <Link
                  className="purple-button"
                  to="/about"
                  onClick={() => setModalShow(true)}
                >
                  {data.ourStoryButtonText}
                </Link>
              </div>
            </Col>
            <Col className="OurStory__col" xs={{ order: isMobile ? 1 : 12 }}>
              <Image
                src={data.ourStoryImage?.url}
                className="OurStory__founder"
                alt={data.ourStoryImage?.title}
              />
            </Col>
            <Image
              src={data.vrindaSignatureImage?.url}
              className="OurStory__signature"
              alt={data.vrindaSignatureImage?.title}
            />
          </Row>
        </Container>
        <VentureCapitals
          header={data.ventureCapitalHeader}
          logos={data.ventureCapitalLogos}
        />
        <Investors
          header={data.backersHeader}
          subHeader={data.backersSubheader?.childMarkdownRemark?.html}
          backers={data.backers}
          bottomText={data.backersBottomText}
        />
        <Careers
          label={data.careersLabel}
          header={data.careersHeader}
          subheader={data.careersSubheader}
          linkText={data.careersLinkText}
          linkUrl={data.careerLinkUrl}
          valueOneText={data.careerValueOneText}
          valueOneImage={data.careerValueOneImage}
          valueTwoText={data.careerValueTwoText}
          valueTwoImage={data.careerValueTwoImage}
          valueThreeText={data.careerValueThreeText}
          valueThreeImage={data.careerValueThreeImage}
          bottomText={data.careersBottomText}
        />
        <Journey
          header={data.ourJourneyHeader}
          linkText={data.ourJourneyLinkText}
          journeyOneText={data.journeyOneText}
          journeyOneImage={data.journeyOneImage}
          journeyOneUrl={data.journeyOneUrl}
          journeyTwoText={data.journeyTwoText}
          journeyTwoImage={data.journeyTwoImage}
          journeyTwoUrl={data.journeyTwoUrl}
          journeyThreeText={data.journeyThreeText}
          journeyThreeImage={data.journeyThreeImage}
          journeyThreeUrl={data.journeyThreeUrl}
        />
        <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
      </main>
    )
  );
};

export default AboutPage;
