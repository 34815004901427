import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import './index.scss';

const Journey = ({
  header,
  linkText,
  journeyOneText,
  journeyOneImage,
  journeyOneUrl,
  journeyTwoText,
  journeyTwoImage,
  journeyTwoUrl,
  journeyThreeText,
  journeyThreeImage,
  journeyThreeUrl
}) => {
  return (
    <div>
      <section className="Journey site-wrap">
        <div className="Journey__header">
          <div className="Journey__left">
            <h2>{header}</h2>
          </div>
          <div className="Journey__right">
            <Link to="/blog">{linkText}</Link>
          </div>
        </div>
        <div className="Journey__columns" style={{ marginTop: '50px' }}>
          <a className="Journey__column" href={journeyOneUrl}>
            <div className="Journey__image">
              <Image src={journeyOneImage.url} alt={journeyOneImage.alt} />
            </div>
            <div className="Journey__line">{journeyOneText}</div>
          </a>
          <a className="Journey__column" href={journeyTwoUrl}>
            <div className="Journey__image">
              <Image src={journeyTwoImage.url} alt={journeyTwoImage.title} />
            </div>
            <div className="Journey__line">{journeyTwoText}</div>
          </a>
          <a className="Journey__column" href={journeyThreeUrl}>
            <div className="Journey__image">
              <Image
                src={journeyThreeImage.url}
                alt={journeyThreeImage.title}
              />
            </div>
            <div className="Journey__line">{journeyThreeText}</div>
          </a>
        </div>
      </section>
      <section className="JourneyMobile site-wrap">
        <div className="Journey__columns">
          <a className="Journey__column" href={journeyOneUrl}>
            <div className="Journey__image">
              <Image src={journeyOneImage.url} alt={journeyOneImage.title} />
            </div>
            <div className="Journey__line">{journeyOneText}</div>
          </a>
          <a className="Journey__column" href={journeyTwoUrl}>
            <div className="Journey__image">
              <Image src={journeyTwoImage.url} alt={journeyTwoImage.title} />
            </div>
            <div className="Journey__line">{journeyTwoText}</div>
          </a>
          <a className="Journey__column" href={journeyThreeUrl}>
            <div className="Journey__image">
              <Image
                src={journeyThreeImage.url}
                alt={journeyThreeImage.title}
              />
            </div>
            <div className="Journey__line">{journeyThreeText}</div>
          </a>
        </div>
        <div className="Journey__blog">
          <a target="_blank" href="/blog">
            {linkText}
          </a>
        </div>
      </section>
    </div>
  );
};

Journey.propTypes = {
  header: PropTypes.string,
  journeyOneImage: PropTypes.obj,
  journeyOneText: PropTypes.string,
  journeyOneUrl: PropTypes.string,
  journeyTwoImage: PropTypes.obj,
  journeyTwoText: PropTypes.string,
  journeyTwoUrl: PropTypes.string,
  journeyThreeImage: PropTypes.obj,
  journeyThreeText: PropTypes.string,
  journeyThreeUrl: PropTypes.string,
  linkText: PropTypes.string
};

export default Journey;
