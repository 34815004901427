import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const VentureCapitals = ({ header, logos }) => {
  return (
    <div className="ventureCapitals pt-4">
      <div className="site-wrap">
        <h3>{header}</h3>
        <ul className="d-flex align-items-center ventureCapitals__container">
          {logos.map((logo, i) => (
            <li key={logo.title} className="ventureCapitals__feature">
              <img
                src={logo.url}
                alt={logo.title}
                className="ventureCapitals__img"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

VentureCapitals.propTypes = {
  header: PropTypes.string,
  logos: PropTypes.array
};

export default VentureCapitals;
